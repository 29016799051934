import React, { useState } from 'react';
import PleaseLogin from '../pleaseLogin';

function StaticHomePage(props) {
    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen)
    };
    const handleClick = () => {
        if(modalIsOpen) return;
        toggleModal();
    };

    return (
        <main className="slow-fade" onClickCapture={handleClick} >
            <PleaseLogin 
                toggleModal={toggleModal} 
                modalIsOpen={modalIsOpen} 
            />
            <h1>Curriculum Overview</h1>
            <i>Click on topic title to access content</i>
            <div style={{marginTop: '2rem'}} className="topics topics-disabled">
                <table>
                    <thead>
                        <tr>
                            <th>Day</th>
                            <th>Topics</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><a href="/curriculum/day-1/">1</a></td>
                            <td><a href="/curriculum/day-1/">Intro to React &#x26;&#x26; Styling React
                                    Components</a></td>
                        </tr>
                        <tr>
                            <td><a href="/curriculum/day-2/">2</a></td>
                            <td><a href="/curriculum/day-2/">Component State w/ Hooks &#x26;&#x26; React w/ AJAX
                                </a></td>
                        </tr>
                        <tr>
                            <td><a href="/curriculum/day-3/">3</a></td>
                            <td><a href="/curriculum/day-3/">Client-Side Routing &#x26;&#x26; Handling Form Inputs
                                    w/ Controlled Components</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <br />
        </main>
    );
}

export default StaticHomePage