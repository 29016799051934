import React from 'react';
import Modal from 'react-modal';
import * as styles from './pleaselogin.module.scss';
import LoginForm from '../loginForm'

Modal.setAppElement('#___gatsby');

const PleaseLogin = ({ modalIsOpen, toggleModal }) => {

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 100
        },
    };
    return (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          style={customStyles}
          contentLabel="Please Login"
        > <div className={styles.modalContainer}>
            <h3>Please Login</h3>
              <LoginForm />
        </div>
        </Modal>
    );
}


export default PleaseLogin;