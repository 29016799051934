import React from 'react';

import { SearchBox, Configure } from 'react-instantsearch-dom';



const Search = (props) => {

    return (
            <>
                <SearchBox />
                <Configure 
                    hitsPerPage={3}
                />
            </>
    );
};

export default Search;