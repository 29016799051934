import React, { useState, useRef } from 'react';
import { Hits, connectStateResults  } from 'react-instantsearch-dom';
import { Link, navigate } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import Search from '../search';
import { isLoggedIn, logout } from '../../services/auth';
import logo from '../../../static/logo.svg';
import './responsiveNav.css';

const ResponsiveNav = ({ 
    title, 
    navigationLinks, 
    searchState, 
    setSearchState, 
    isSmallScreen }) => {
    
  const [isNavVisible, setNavVisibility] = useState(false);
  const Results = connectStateResults(({ searchState, searchResults, children }) => {
    if(searchState && searchState.query && children) {
        return children;
    } else {
        return null;
    }
  });
  
  
  
  const Hit = useRef(({ hit }) => {
    const handleClick = () => {
        setSearchState({...searchState, query: ''})
    };
    return (
        <div style={{display: 'flex', flexDirection: 'column' }}>
            <section style={{display: 'flex', justifyContent: 'flex-start'}}>
              <Link to={hit.url} onClick={handleClick} style={{display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                  <img style={{ height: 25, margin: '0px 5px 0px 0px' }} src={logo} alt={hit.title} />
                  <h4>{hit.title.replace(' | React With Dan Course', '')}</h4>
              </Link>
            </section>
            <p style={{marginTop: '1rem'}}>{hit.content.slice(0, 125) + ' ...'}</p>
        </div>
    );
  });
  


  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };
  
  const handleLogout = async () => {
    setNavVisibility(false);
    logout();
    navigate('/');
  };

  const links = navigationLinks.map(({ title, slug}, idx) => (
    <Link className="link" style={{fontSize: !searchState || (searchState.query && 'small')}} key={idx} to={slug} onClick={toggleNav}>{title}</Link>
  ));


  const mobileLinks = navigationLinks.map(({ title, slug}, idx) => (
    <Link className="link" key={idx} to={slug} onClick={toggleNav}>{title}</Link>
  ));

  return (
    <div className="nav-wrapper">
      <Link onClick={() => setNavVisibility(false)} className="logo-link" to="/">
        <img className="logo" src={logo} alt={title} />
      </Link>
      <CSSTransition
        in={isSmallScreen && isNavVisible}
        timeout={350}
        classNames="navAnimation"
        unmountOnExit
      >
        <nav className="nav">
          {
              isLoggedIn() ?
              <span 
                tabIndex="0" 
                role="button" 
                className="link" 
                onClick={handleLogout} 
                onKeyDown={handleLogout}>
                Logout
              </span>
              :
              <Link 
                className="link" 
                onClick={() => setNavVisibility(false)}
                to="/login" 
                >
                Login
              </Link>
            }
            { mobileLinks }
        </nav>
      </CSSTransition>
      {
        !isSmallScreen && (
          <nav className="nav">
          {
              isLoggedIn() ?
              <span
                style={{fontSize: !searchState || (searchState.query && 'small')}} 
                tabIndex="0" 
                role="button" 
                className="link" 
                onClick={handleLogout} 
                onKeyDown={handleLogout}>
                Logout
              </span>
              :
              <Link
                style={{fontSize: !searchState || (searchState.query && 'small')}} 
                className="link" 
                onClick={() => setNavVisibility(false)}
                to="/login" 
                >
                Login
              </Link>
            }
            { links }
            {
              isLoggedIn() && (
                <>
                  <div style={{position: 'fixed', top: 70, right: 80, animation: 'fadeIn 500ms linear forwards'}}>
                    <Results> 
                        <Hits 
                          hitComponent={Hit.current}
                        />
                    </Results>
                </div>
                <Search />
              </>
              )
            }
          </nav>
        )
      }
      <button onClick={toggleNav} className="burger">
        <div className={isNavVisible ? 'burger-animate': 'fixed'} />
        <div className={isNavVisible ? 'burger-animate': 'fixed'} />
        <div className={isNavVisible ? 'burger-animate': 'fixed'} />
      </button>
    </div>
  );
};

export default ResponsiveNav;