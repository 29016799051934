import React, { Fragment, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import NavBar from '../responsiveNav';
import Footer from '../footer';
import Timer from '../timer';
import StaticHomePage from '../staticHome';
import { isLoggedIn } from '../../services/auth';

import * as styles from './layout.module.scss';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import FeedbackModal from '../feedbackModal';
import FeedbackForm from '../feedbackForm';



const Layout = (props) => {
    const [focusState, setFocusState ] = useState(false);
    const [searchState, setSearchState ] = useState({});
    const [isSmallScreen, setIsSmallScreen] = useState(true);
    const [isSmallerScreen, setIsSmallerScreen] = useState(true);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [feedbackModalIsOpen, setIsFeedbackModalOpen] = useState(false);
    const searchClient = algoliasearch('6PGX6NPGG7', '4f68c21d5bfb67163d5070f36fe5ced0');

    const { site, file } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    navigationLinks {
                        title
                        slug
                    }
                }
            }
            file(name: {eq: "daniel_silouette"}) {
                childImageSharp {
                    fluid(maxWidth: 1800) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                    }
                }
            }
        }
    `);
    



    function toggleModal() {
        if(isSmallScreen) return;
        setIsOpen(!modalIsOpen);
    }

    function toggleFeedbackModal() {
        setIsFeedbackModalOpen(!feedbackModalIsOpen);
    }



    function handleKeyboard({ repeat, ctrlKey, key }) {
        if (repeat) return;
        if ((ctrlKey && key === 't')) toggleModal();
    }



    function handleMediaQueryChangeSmallScreen (mediaQuery) {
        if (mediaQuery.matches) {
          setIsSmallScreen(true);
        } else {
          setIsSmallScreen(false);
        }
    };

    function handleMediaQueryChangeSmallerScreen (mediaQuery) {
        if (mediaQuery.matches) {
          setIsSmallerScreen(true);
        } else {
          setIsSmallerScreen(false);
        }
    };

    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyboard);
        return () => {
            document.removeEventListener('keydown', handleKeyboard);
        }
    })


    
    useEffect(() => {
        
        const mediaQuery = window.matchMedia("(max-width: 1065px)");
        mediaQuery.addEventListener('change', handleMediaQueryChangeSmallScreen);

        const mediaQuerySmaller = window.matchMedia("(max-width: 746px)");
        mediaQuery.addEventListener('change', handleMediaQueryChangeSmallerScreen);
    
        handleMediaQueryChangeSmallScreen(mediaQuery);
        handleMediaQueryChangeSmallerScreen(mediaQuerySmaller);
    
        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChangeSmallScreen);
            mediaQuery.removeEventListener('change', handleMediaQueryChangeSmallerScreen);
        };
    }, []);


    return (
        <Fragment>
            <div className={styles.outerContainer}>
            <InstantSearch
                searchClient={searchClient}
                indexName={'netlify_e3fd766b-680c-4eaf-b2cd-dc08239c315b_master_all'}
                searchState={searchState}
                onSearchStateChange={setSearchState}
            >
                <NavBar
                    isSmallScreen={isSmallScreen}
                    searchState={searchState}
                    setSearchState={setSearchState} 
                    title={site.siteMetadata.title}
                    focusState={focusState}
                    setFocusState={setFocusState}
                    navigationLinks={site.siteMetadata.navigationLinks}
                />
                { 
                    props.location && props.location.pathname === '/' 
                    ? (
                        <header className="site-Banner">
                            <Img {...file.childImageSharp} />
                            <div className="Banner-details">
                                <div>
                                    <h1>React with Dan</h1>
                                    <span className="sub-title">Welcome to your ReactJS Course</span>
                                </div>
                            </div>
                        </header>
                    )
                    :
                    <Timer 
                        modalIsOpen={modalIsOpen}
                        toggleModal={toggleModal}
                    />   
                }
                
                </InstantSearch>
                <FeedbackModal feedbackModalIsOpen={feedbackModalIsOpen} toggleFeedbackModal={toggleFeedbackModal} />
                <div
                    style={{paddingTop: props.location && props.location.pathname === '/' ? '1rem': '7rem'}}
                    className={styles.innerContainer}>
                    { !isLoggedIn() && props.location && props.location.pathname === '/' ?
                        <StaticHomePage isSmallerScreen={isSmallerScreen} />
                        :
                        props.children
                    }
                </div>
                <Footer toggleFeedbackModal={toggleFeedbackModal} />
            </div>
            <FeedbackForm shouldHide={true} />
        </Fragment>
    );
};

export default Layout;