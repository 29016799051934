import firebase from 'firebase/app';
import 'firebase/auth';

export default new Proxy({
    get auth() {
        return firebase.auth();
    }
}, {
    get: function(target, name) {
        if(!firebase.apps.length) {
            firebase.initializeApp({
                apiKey: "AIzaSyAOux_-DzcAoqYhUv7SC-XSOHE1ETrw6hs",
                authDomain: "react-with-dan.firebaseapp.com",
                projectId: "react-with-dan",
                storageBucket: "react-with-dan.appspot.com",
                messagingSenderId: "435071347289",
                appId: "1:435071347289:web:fdc626c2cf77f1cbdf1f48",
                measurementId: "G-XHD8NMTT88"
        
            })
        }
        return target[name]
    }
});
