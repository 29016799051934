import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import * as styles from './footer.module.scss';
import { isLoggedIn } from '../../services/auth';

const Footer = ({ toggleFeedbackModal }) => {
    return (
    <footer className={styles.footer}>
        <p>Copyright &copy; <a href="https://danieljs.io" target="_blank" rel="noopener noreferrer">DanielJS</a> {new Date().getFullYear()}</p>
        <section>
            <a href="https://github.com/myDeveloperJourney" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} size="2x"/>
            </a>
            <a href="https://linkedin.com/in/daniel-j-scott" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="2x"/>
            </a>
            <a href="https://www.youtube.com/channel/UCmPSUtVgth9zyXpzOM5lNFQ" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
        </section>
        {
            isLoggedIn() &&
                <div style={{ padding: '.5rem', marginBottom: '2rem'}}>
                    <button
                        className="feedback-button"
                        style={{ 
                            backgroundColor: '#fff', 
                            border: 'none', 
                            cursor: 'pointer'
                        }} onClick={toggleFeedbackModal}>Feedback</button> 
                </div>
            }
        </footer>
    );
}

export default Footer;