import React, { useState } from 'react';
import * as styles from './feedbackform.module.scss';

function FeedbackForm({ setShowHeading, toggleFeedbackModal, shouldHide = false }) {
    const [state, setState] = useState({
        name: "",
        email: "",
        message: "",
        formSubmitted: false
    });

    const encode = (data) => {
        const {name, email, message} = data
        return `form-name=Feedback&name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&message=${encodeURIComponent(message)}`
    }

    function handleChange(e) {
        setState({...state, [e.target.name]: e.target.value })
    }

    async function handleSubmit(e) {
        e.preventDefault();
        await fetch('/', {
            method: 'POST',
            headers: {
                'Content-type': 'application/x-www-form-urlencoded'
            },
            body: encode(state)
        })
        setState({...state, formSubmitted: true});
        setShowHeading(false)
        setTimeout(() => {
            toggleFeedbackModal();
            setShowHeading(true)
            setState({
            name: "",
            email: "",
            message: "",
            formSubmitted: false})
        }, 3000);
    }

    return (
        <>
            {state.formSubmitted ? <h3 style={{ textAlign: 'center', marginTop: '1.7rem'}}>Thank You For Your Feedback! 🎉</h3> :
                <form style={{display: shouldHide ? 'none': 'block'}} name="Feedback" data-netlify="true" onSubmit={handleSubmit} className={styles.Form}>
                    <input type="hidden" name="form-name" value="Feedback" />
                    <div>
                        <label>Your name
                        <input placeholder="Alan Turing" type="text" name="name" required value={state.name}  onChange={handleChange}/></label>
                    </div>
                    <div>
                        <label>Your Email: 
                        <input placeholder="ada@lovelace.com" type="email" name="email" required value={state.email}  onChange={handleChange}/></label>
                    </div>
                    <div>
                        <label>Message: 
                        <textarea placeholder="Here are some suggestions, concerns and/or questions I had for you" name="message" required value={state.message} onChange={handleChange}></textarea></label>
                    </div>
                    <div>
                        <button className="btn btn-dark" type="submit" required>Send</button>
                    </div>
                </form>
            }
       
        </>
    );
}

export default FeedbackForm;