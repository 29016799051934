import { navigate } from 'gatsby';
import { isBrowser, isLoggedIn, getToken } from './src/services/auth';
import { setReferrerLink } from './src/services/url-referrer';
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css';
import './src/styles/base.scss';


export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  );
  
  if (answer === true) {
    window.location.reload();
  };
}


export const onRouteUpdate = ({ location }) => {
  if(location && location.pathname.includes('curriculum') && isBrowser() && !isLoggedIn()) {
    setReferrerLink(location.pathname);
    navigate('/login');
  } 
};



export const onPrefetchPathname = async () => {
  if(isBrowser() && window.location.pathname.includes('curriculum')) {
    const token = getToken()
    if(!token) {
      setReferrerLink(window.location.pathname)
      return navigate('/login')
    } 

    const response = await fetch('/.netlify/functions/verify', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token
        }
    });

    if(response.status !== 200) {
      setReferrerLink(window.location.pathname)
      return navigate('/login')
    }
  } 
}
