// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-markdown-remark-fields-slug-js": () => import("./../../../src/pages/{MarkdownRemark.fields__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-fields-slug-js" */),
  "component---src-pages-submit-assignment-js": () => import("./../../../src/pages/submit-assignment.js" /* webpackChunkName: "component---src-pages-submit-assignment-js" */)
}

