import React, { useState } from 'react';
import Modal from 'react-modal';
import * as styles from './feedbackmodal.module.scss';
import FeedbackForm from '../feedbackForm/index';

Modal.setAppElement('#___gatsby');

const FeedbackModal = ({ feedbackModalIsOpen, toggleFeedbackModal }) => {
    const [showHeading, setShowHeading ] = useState(true)

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 100
        },
    };
    return (
        <Modal
          isOpen={feedbackModalIsOpen}
          onRequestClose={toggleFeedbackModal}
          style={customStyles}
          contentLabel="Please Provide Feedback"
        > <div className={styles.modalContainer}>
            <h3 style={{ display: showHeading ? 'block' : 'none' }}>Please Provide Feedback</h3>
              <FeedbackForm toggleFeedbackModal={toggleFeedbackModal} setShowHeading={setShowHeading}/>
        </div>
        </Modal>
    );
}


export default FeedbackModal;