import firebase from './firebase';
import { setReferrerLink } from './url-referrer';


function login(email, password) {
    return firebase.auth.signInWithEmailAndPassword(email, password);
}

function signOut() {
    return firebase.auth.signOut();
}

export const isBrowser = () => typeof window !== "undefined";

const parseUserFromStorage = () => {
    return JSON.parse(window.localStorage.getItem('user'));
}

const checkExp = () => {
    if(parseUserFromStorage() && JSON.parse(window.atob(JSON.parse(window.localStorage.getItem('user')).token.split('.')[1])).exp * 1000 >= Date.now()) {
        return true;
    } else {
        window.localStorage.removeItem('user');
        return false
    }
}

export const getToken = () => (
    isBrowser() ? window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')).token : null : null
);

export const getUser = () => (
    isBrowser() && checkExp()
    ? parseUserFromStorage()
    : null
);

const setUser = user => window.localStorage.setItem('user', JSON.stringify(user));


export const handleLogin = (email, password) => {
    return login(email, password).then(({ user }) => {
        return user.getIdToken();
    }).then(token => {
        setUser({token, email, username: email.split('@')[0]});
        return {
            email,
            username: email.split('@')[0]
        };
    })
};

export const isLoggedIn = () => {
    const user = getUser();
    return !!user;
};

export const logout = () => {
    window.localStorage.removeItem('user');
    setReferrerLink('/')
    return signOut();
};